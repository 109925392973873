* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}
.generalContainer {
  background-color: #353535;
}
.news,
.about,
.links,
.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.news {
  background-position: center;
  background-size: cover;
  background-color: darkslategray;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #d2d2d2;
  font-size: 40px;
  font-weight: 600;
}

.about_text {
  color: #ffffff;
  font-size: 24px;
  padding: 9px 90px 24px 90px;
  text-align: center;
}

.about_text_small {
  color: #ffffff;
  font-size: 18px;
  padding: 9px 60px 24px 60px;
}

.about_text a {
  text-decoration: none;
  color: aquamarine;
}
.about_text a:hover {
  border-bottom: 1px solid aquamarine;
  transition: all 0.2s ease-out;
}

.about_text i {
  color: coral;
  font-size: 18px;
  margin-right: 5px;
}

.links {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}
.links_container {
  width: 80vw;
  margin: auto;
}
.links_items {
  display: inline-flex;
  width: 25vw;
  height: 180px;
  padding: 20px;
  margin: 10px;
  border-color: black;
  border-radius: 18px;
  border-style: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
@media only screen and (max-width: 768px) {
  .about_text {
    color: #ffffff;
    font-size: 18px;
    padding: 9px 50px 24px 50px;
  }
}