video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    background: url('images/ring-ring-cover.png') center center/cover no-repeat;
    background-color: #111113;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}
.hero-container > h1 {
    color: #fed156;
    font-size: 72px;
    font-weight: 500;
}
.hero-container > h4 {
    color: #ffe59c;
    font-size: 52px;
    font-weight: 500;
}

.hero-container > h3 {
    margin-top: 1px;
    color: #fff;
    font-size: 30px;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}
.hero-container > p {
    font-size: 24px;
    color: #e2e2e2;
    padding-right: 20vh;
    padding-left:20vh;
    padding-top: 1px;
    padding-bottom: 10px;
    font-family: 'Oswald', sans-serif;
}
.hero-container > .social-icons {
    align-content: center;
}

.hero-btns {
    margin-top: 52px;
    margin-bottom: 50px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;

}

@media screen and (max-width: 1280px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: 0px;
    
    }
    .hero-container > h3 {
        font-size: 28px;
    }

    .hero-container > p {
        font-size: 24px;
        padding-left: 5vh;
        padding-right: 5vh;
    }  
}

@media screen and (max-width: 968px) {
    .hero-container > h1 {
        font-size: 42px;
        margin-top: 10px;
    
    }  
    .hero-container > h3 {
        font-size: 24px;
    }

    .hero-container > h4 {
        font-size: 28px;
    }

    .hero-container > p {
        font-size: 18px;
        padding-left: 5vh;
        padding-right: 5vh;
    }  
    .btn-mobile {
        display: block;
        text-decoration: none;

    }
    .btn {
        width: 100%;
    }
}

@media screen and (max-width: 750px) {
    .hero-container > h1 {
        font-size: 36px;
        margin-top: 0px;
    
    }  
    .hero-container > h3 {
        font-size: 28px;
    }
    .hero-container > h4 {
        font-size: 28px;
    }

    .hero-container > p {
        font-size: 18px;
        padding-left: 5vh;
        padding-right: 5vh;
    }  
    .btn-mobile {
        display: block;
        text-decoration: none;

    }
    .btn {
        width: 100%;
    }
}